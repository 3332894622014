export const loginForm = {
  display: `flex`,
  // margin: `0 -0.625rem`,
  flexWrap: `wrap`,
};

export const additionalActions = {
  textAlign: `center`,
  display: `flex`,
  flexDirection: `column`,
  alignItems: `center`,
};

export const linkStyle = { marginTop: `15px` };
