export default [
  {
    title: '',
    fields: [
      {
        type: 'input',
        inputType: 'text',
        width: '100%',
        name: 'street_address',
        label: 'Street Address',
        required: true,
      },
      {
        type: 'input',
        inputType: 'text',
        width: '100%',
        name: 'city',
        label: 'Town/City',
        required: true,
      },
      {
        type: 'input',
        inputType: 'text',
        width: '100%',
        name: 'county',
        label: 'County',
        required: true,
      },
      {
        type: 'input',
        inputType: 'text',
        width: '100%',
        name: 'post_code',
        label: 'Post Code',
        required: true,
      },
      {
        type: 'input',
        inputType: 'number',
        width: '100%',
        name: 'phone_number',
        label: 'Phone Number',
        required: false,
      },
      {
        type: 'submit',
        width: '100%',
        buttonLabel: 'Save Address',
      },
    ],
  },
];
