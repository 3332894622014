import React, { useEffect, useContext, useState } from 'react';

import addressFields from './address';

import { FirebaseContext } from '../Firebase';
import { FormContext } from '../Form/context';
import FormFields from '../Form/fields';

import { loginForm } from './style';

import { SubmitPlaceholder } from '../Form/placeholders';

import loadComponents from '../Loadable';

const Button = loadComponents('button-input');
// const Fields = loadComponents('form-fields');

const successMessage = 'Address updated successfully.';
const deleteMessage = 'Address deleted successfully.';

export default function AccountContact({
  authUser,
  displaySuccess,
  displayError,
}) {
  const [address, setAddress] = useState(false);
  const [active, setActive] = useState(false);

  const firebase = useContext(FirebaseContext);
  const form = useContext(FormContext);

  useEffect(() => {
    if (firebase && authUser) {
      const { address, readDatabase } = firebase;
      readDatabase(address(authUser.uid), snapshot => {
        const address = snapshot.val();
        if (address !== null) {
          form.bulkChange({
            ...address,
          });
          setAddress(true);
        }
      });
    }
  }, [firebase]);

  const handleSubmit = e => {
    e.preventDefault();

    const { setDatabase, address } = firebase;
    const { street_address, city, county, post_code, phone_number } =
      form.values;

    setDatabase(address(authUser.uid), {
      street_address,
      city,
      county,
      post_code,
      phone_number: phone_number || null,
    })
      .then(() => {
        displaySuccess(successMessage);
      })
      .catch(error => {
        displayError(error.message);
      })
      .finally(() => setActive(false));
  };

  const deleteAddress = () => {
    const { deleteDatabase, address } = firebase;

    deleteDatabase(address(authUser.uid))
      .then(() => {
        setAddress(false);
        form.bulkChange({
          street_address: '',
          city: '',
          county: '',
          post_code: '',
          phone_number: '',
        });
        displaySuccess(deleteMessage);
      })
      .catch(error => {
        displayError(error.message);
      });
  };

  const toggleEdit = () => {
    setActive(!active);
  };

  const { street_address, city, county, post_code, phone_number } = form.values;

  return (
    <div className="addressOuter">
      {address
        ? !active && (
            <>
              <p>
                {street_address}
                <br />
                {city}
                <br />
                {county}
                <br />
                {post_code}
                <br />
                {phone_number}
              </p>
              <Button value="Edit" onClick={() => toggleEdit()} primary />
              <Button
                value="Delete"
                style={{ marginTop: `0.625rem` }}
                onClick={() => deleteAddress()}
                fallback={<SubmitPlaceholder />}
                secondary
              />
            </>
          )
        : !active && (
            <>
              <p>You have no saved addresses</p>
              <Button
                value="Add Address"
                onClick={() => toggleEdit()}
                fallback={<SubmitPlaceholder />}
                primary
              />
            </>
          )}
      {active && (
        <>
          <form name="address" onSubmit={handleSubmit} style={loginForm}>
            {addressFields.map((group, index) => (
              <div
                className="group"
                key={group.title}
                style={{
                  display: `flex`,
                  flexWrap: `wrap`,
                  margin: `0 -15px`,
                  padding: index === 0 && 0,
                }}
              >
                <FormFields data={group.fields} />
              </div>
            ))}
          </form>
          <Button value="Cancel" onClick={() => toggleEdit()} secondary />
        </>
      )}
    </div>
  );
}
